import axios from 'axios'
import config from '@/config'

let httpClient = axios.create({
  baseURL: config.env.BACKEND_URL,
  timeout: 50000,
  withCredentials: true
})

export default httpClient
