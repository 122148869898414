module.exports = {
  userGroups: {
    // Azure IDs of User Groups
    admin: 'db1b1553-5118-4c47-84cd-ab8e993f1e9a',
    contractor: '265aa1ae-35c8-4191-950a-d1040ac840e1',
    operator: '51ff2512-15e6-4157-9ad2-b36583ff5e1f'
  },
  env: {
    BACKEND_PORT: process.env.VUE_APP_BACKEND_PORT,
    BACKEND_URL: process.env.VUE_APP_BACKEND_URL,
    PORT: process.env.VUE_APP_PORT,
    URL: process.env.VUE_APP_URL, // for Vue Config
    MAP_THEME_PATH: process.env.VUE_APP_MAP_THEME_PATH,
    MAP_ACCESS_KEY: process.env.VUE_APP_MAP_ACCESS_KEY
  }
}
